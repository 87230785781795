<template>
  <div>
    <b-input-group>
      <b-form-input readonly :value="parsedFilter"/>

      <b-input-group-append>
        <b-button variant="outline-secondary" @click="() => openModal()">
          <i class="bx bxs-bar-chart-alt-2"/>
        </b-button>
        <b-button variant="outline-secondary" @click="$emit('share')" :disabled="loadingShare">
          <i v-if="!loadingShare" class="bx bx-share-alt"/>
          <i v-else class="bx bx-loader bx-spin"/>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <range-date-picker v-model="filterDate" ref="datePicker"/>
  </div>
</template>

<script>
import moment from 'moment'
import RangeDatePicker from '@/components/range-date-picker'
import { parseDataParam } from '@/modules/dashboards/toolbox/parseDataParam'

function formatDate (date) {
  return moment(date).format('DD [de] MMM')
}

const filterDate = [new Date(), new Date()]
filterDate[0].setDate(filterDate[1].getDate() - 4)

export default {
  name: 'dashboard-input-filter',
  components: { RangeDatePicker },
  props: {
    loadingShare: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    filterDate
  }),
  computed: {
    parsedFilter () {
      if (!(this.filterDate[0] instanceof Date)) {
        return ''
      }
      const dt1 = formatDate(this.filterDate[0])
      const dt2 = formatDate(this.filterDate[1])

      return `${dt1} - ${dt2}`
    }
  },
  methods: {
    openModal () {
      this.$refs['datePicker'].open()
    }
  },
  watch: {
    filterDate: {
      handler (value, oldValue) {
        const valueParsed = `${parseDataParam(value[0])}-${parseDataParam(value[1])}`
        const oldValueParsed = `${parseDataParam(oldValue[0])}-${parseDataParam(oldValue[1])}`
        if (valueParsed !== oldValueParsed) {
          this.$emit('filter-date', value)
        }
      },
      deep: true
    }
  },
  created () {
    this.$emit('filter-date', this.filterDate)
  }
}
</script>

<style scoped>

</style>
