<template>
  <Layout>
    <b-row class="mb-3">
      <b-col>
        <div class="header-dashboard">
          <div class="title-current-dashboard">{{ $route.meta.dashboard.title }}</div>
          <div class="path-current-dashboard">{{ $route.meta.dashboard.pathDat($route.params) }}</div>
          <div class="info-current-dashboard">{{ $route.meta.dashboard.info }}</div>
          <div class="back-current-dashboard" v-if="$route.meta.dashboard.backRoute">
            <router-link :to="$route.meta.dashboard.backRoute($route.params)" class="btn btn-link" title="Voltar">
              <i class='bx bx-arrow-back'></i>
            </router-link>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-3 flex-grow-1" cols="12" sm="auto">
        <b-form-select
            v-model="currentDashboardSelected" :options="options"
            @change="symbol => navToDashboardSymbol(symbol)"
        ></b-form-select>
      </b-col>
      <b-col class="mb-3" cols="12" sm="5" lg="4" xl="3" v-if="showDashboardFilter">
        <dashboard-input-filter @share="printPage" @filter-date="updateFilterDate" :loading-share="loadingPrint"/>
      </b-col>
    </b-row>
    <b-row class="mb-3" ref="printable-content">
      <b-col class="page-printable">
        <router-view/>
      </b-col>
    </b-row>
    <VueHtml2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        filename="dashboard.pdf"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        :html-to-pdf-options="{
          margin: [0.2, 0.25],
          filename: 'dashboard.pdf',
          image: {
              type: 'jpeg',
              quality: 0.98
          },
          html2canvas: {
              useCORS: true,
              dpi: 192,
              scale:4,
              letterRendering: true,
          },
          jsPDF: {
            unit: 'in',
            orientation: 'landscape',
            format: 'a4',
          }
        }"
        @hasDownloaded="loadingPrint = false"
        ref="html2Pdf"
    >
      <div slot="pdf-content" class="pdf-content">
        <div class="container">
          <b-col>
            <div class="header-dashboard">
              <div class="title-current-dashboard">{{ $route.meta.dashboard.title }}</div>
              <div class="path-current-dashboard">{{ $route.meta.dashboard.pathDat($route.params) }}</div>
              <div class="info-current-dashboard">{{ $route.meta.dashboard.info }}</div>
            </div>
          </b-col>
        </div>
        <div class="container" ref="pdf-content" style="width: 1050px"></div>
      </div>
    </VueHtml2pdf>
  </Layout>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import * as dashboardSymbols from './dashboardsSymbols'
import Layout from '@/components/layout/vertical'
import DashboardInputFilter from '@/components/dashboards/dashboard-input-filter'

const availableDashboardsData = [
  {
    symbol: dashboardSymbols.DADOS_GERAIS_REAL_TIME,
    routeName: 'DashboardRealTime',
    selectText: 'Real-time'
  },
  {
    symbol: dashboardSymbols.DADOS_GERAIS_ANALYTICS,
    routeName: 'DashboardAnalytics',
    selectText: 'Analytics'
  },
  {
    symbol: dashboardSymbols.SALAS,
    routeName: 'DashboardTodasSalas',
    selectText: 'Salas'
  }
]

export default {
  name: 'DashboardPage',
  components: {
    VueHtml2pdf,
    DashboardInputFilter,
    Layout
  },
  data: () => ({
    loadingPrint: false,
    availableDashboards: [],
    selected: null,
    options: availableDashboardsData.map((item) => ({
      value: item.symbol,
      text: item.selectText
    })),
    currentDashboardSelected: availableDashboardsData[0].symbol
  }),
  computed: {
    showDashboardFilter () {
      return this.currentDashboardSelected !== dashboardSymbols.DADOS_GERAIS_REAL_TIME && this.currentDashboardSelected !== dashboardSymbols.SALAS
    }
  },
  methods: {
    navToDashboardSymbol (symbol) {
      const dashboard = availableDashboardsData.find(item => item.symbol === symbol)
      if (!dashboard) {
        return
      }
      this.$router.push({ name: dashboard.routeName })
    },
    printPage () {
      this.loadingPrint = true
      this.$nextTick(() => {
        const printableContentDOM = this.$refs['printable-content'].cloneNode(true)
        const pdfContentDOM = this.$refs['pdf-content']
        pdfContentDOM.innerHTML = ''
        pdfContentDOM.append(printableContentDOM)
        this.$refs.html2Pdf.generatePdf()
      })
    },
    updateFilterDate (rangeDate) {
      this.$store.commit('dashboards/filterDates', rangeDate)
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.currentDashboardSelected = this.$route.meta.dashboard.symbol
      },
      { immediate: true }
    )
  }
}
</script>

<style scoped lang="scss">
.header-dashboard {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;

  .title-current-dashboard {
    font-size: 36px;
    font-weight: 500;
  }

  .path-current-dashboard {
    font-size: 18px;
    font-weight: 600;
    margin-left: 1em;
  }

  .info-current-dashboard {
    font-size: 16px;
    font-weight: 400;
    margin-left: 1em;
    background-color: #21CD5B;
    border-radius: 50px;
    color: white;
    padding: 5px 16px;
  }

  .back-current-dashboard {
    margin-left: auto;
    a {
      font-size: 2rem;
    }
  }

  .pdf-content {
    width: 300px;
  }

  .page-printable {
    @media print {
      width: 1110px;
      @page {
        padding: 30px;
      }
    }
  }
}
</style>
