var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('div',{staticClass:"header-dashboard"},[_c('div',{staticClass:"title-current-dashboard"},[_vm._v(_vm._s(_vm.$route.meta.dashboard.title))]),_c('div',{staticClass:"path-current-dashboard"},[_vm._v(_vm._s(_vm.$route.meta.dashboard.pathDat(_vm.$route.params)))]),_c('div',{staticClass:"info-current-dashboard"},[_vm._v(_vm._s(_vm.$route.meta.dashboard.info))]),(_vm.$route.meta.dashboard.backRoute)?_c('div',{staticClass:"back-current-dashboard"},[_c('router-link',{staticClass:"btn btn-link",attrs:{"to":_vm.$route.meta.dashboard.backRoute(_vm.$route.params),"title":"Voltar"}},[_c('i',{staticClass:"bx bx-arrow-back"})])],1):_vm._e()])])],1),_c('b-row',[_c('b-col',{staticClass:"mb-3 flex-grow-1",attrs:{"cols":"12","sm":"auto"}},[_c('b-form-select',{attrs:{"options":_vm.options},on:{"change":symbol => _vm.navToDashboardSymbol(symbol)},model:{value:(_vm.currentDashboardSelected),callback:function ($$v) {_vm.currentDashboardSelected=$$v},expression:"currentDashboardSelected"}})],1),(_vm.showDashboardFilter)?_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12","sm":"5","lg":"4","xl":"3"}},[_c('dashboard-input-filter',{attrs:{"loading-share":_vm.loadingPrint},on:{"share":_vm.printPage,"filter-date":_vm.updateFilterDate}})],1):_vm._e()],1),_c('b-row',{ref:"printable-content",staticClass:"mb-3"},[_c('b-col',{staticClass:"page-printable"},[_c('router-view')],1)],1),_c('VueHtml2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":false,"preview-modal":true,"filename":"dashboard.pdf","pdf-quality":2,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"landscape","pdf-content-width":"100%","html-to-pdf-options":{
        margin: [0.2, 0.25],
        filename: 'dashboard.pdf',
        image: {
            type: 'jpeg',
            quality: 0.98
        },
        html2canvas: {
            useCORS: true,
            dpi: 192,
            scale:4,
            letterRendering: true,
        },
        jsPDF: {
          unit: 'in',
          orientation: 'landscape',
          format: 'a4',
        }
      }},on:{"hasDownloaded":function($event){_vm.loadingPrint = false}}},[_c('div',{staticClass:"pdf-content",attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"container"},[_c('b-col',[_c('div',{staticClass:"header-dashboard"},[_c('div',{staticClass:"title-current-dashboard"},[_vm._v(_vm._s(_vm.$route.meta.dashboard.title))]),_c('div',{staticClass:"path-current-dashboard"},[_vm._v(_vm._s(_vm.$route.meta.dashboard.pathDat(_vm.$route.params)))]),_c('div',{staticClass:"info-current-dashboard"},[_vm._v(_vm._s(_vm.$route.meta.dashboard.info))])])])],1),_c('div',{ref:"pdf-content",staticClass:"container",staticStyle:{"width":"1050px"}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }