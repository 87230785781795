<template>
  <div>
    <b-modal
        ref="modal"
        :id="modalId"
        size="lg"
        cancel-title="Cancelar"
        title="Escolha as datas"
        @show="setRangeFromValue"
        @hidden="setRangeFromValue"
        @ok="emitValue"
    >
      <b-container>
        <b-row>
          <b-col>
            <div>
              <DatePicker
                  is-range
                  locale="pt-br"
                  :columns="$screens({ lg: 2 }, 1)"
                  :step="1"
                  v-model="range"
                  :max-date="new Date()"
              />
            </div>
          </b-col>
          <b-col class="facilitador">
            <div class="separador"></div>
            <div>
              <div>
                <b-button variant="link" @click="() => selfUpdate(30)">Últimos 30 dias</b-button>
              </div>
              <div>
                <b-button variant="link" @click="() => selfUpdate(15)">Últimos 15 dias</b-button>
              </div>
              <div>
                <b-button variant="link" @click="() => selfUpdate(7)">Últimos 7 dias</b-button>
              </div>
              <div>
                <b-button variant="link" @click="() => selfUpdate(0)">Hoje</b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { DatePicker } from 'v-calendar'

export default {
  name: 'range-date-picker',
  components: {
    DatePicker
  },
  props: {
    value: {
      required: true,
      typeOf: Array,
      valid: v => {
        if (!Array.isArray(v)) {
          return false
        }
        if (v.length !== 2 || v.length !== 0) {
          return false
        }
        if (v.length === 2) {
          return v[0] instanceof Date && v[0] instanceof Date
        }
        return true
      },
      default: () => []
    }
  },
  data: () => ({
    modalId: 'modal-' + Math.random().toString(36).slice(-5),
    range: {
      start: null,
      end: null
    }
  }),
  methods: {
    selfUpdate (days) {
      const today = new Date()
      const start = new Date()
      start.setDate(today.getDate() - days)
      this.range = {
        start,
        end: today
      }
    },
    open () {
      this.$refs.modal.show()
    },
    close () {
      this.$refs.modal.hide()
    },
    setRangeFromValue () {
      this.range = {
        start: this.value[0] ?? null,
        end: this.value[1] ?? null
      }
    },
    emitValue () {
      this.$emit('input', [this.range.start, this.range.end])
    }
  },
  watch: {
    value: 'setRangeFromValue'
  }
}
</script>

<style scoped lang="scss">
.facilitador {
  position: relative;
}

.separador {
  height: 100%;
  width: 1px;
  background-color: #ccc;
  position: absolute;
  left: 0;
  @media (max-width: 575px) {
    display: none;
  }
}
</style>
